import React from "react";
import { useParams } from "react-router-dom";
import { CASINO_VIDEO_URL } from "../utils/constants";

export default function VideoIframe() {
  const { id } = useParams();

  return (
    <iframe
      style={{ width: "100%", height: "100vh" }}
      src={`${CASINO_VIDEO_URL}${id}`}
    />
  );
}
