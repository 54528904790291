import { AXIOS_INSTANCE } from ".";
import store from "../redux/store";
import { BASE_URL, getToken } from "../utils/constants";

export const loginAPI = async (username, password) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(`${BASE_URL}/auth/signIn`, {
      username,
      password,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const demoLoginAPI = async (username, password) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(`${BASE_URL}/auth/demo-signIn`, {
      username,
      password,
      isDemo: true,
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const registerAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/auth/signup`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const changePasswordAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/auth/password`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const checkValidEmailAPI = async (email) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/valid/email/${email}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const checkValidUsernameAPI = async (username) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/valid/username/${username}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getUserByIdAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/auth/user/${id}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const getButtonValuesAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/user/client-button`);
    return data;
  } catch (error) {
    return error;
  }
};
export const getBannerAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/banner/getAllBanner`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const changeButtonValuesAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/user/update-client-button/`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const betHistoryAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/bet-history`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const unSetteledBetsAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/unsettled-bets`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const profitLossReportAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/profit-loss-report`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const currentBetsAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/current-bets`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getActivityLogAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/activity-log`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getInternationalBetsAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/ICasino/BetHistory`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getExposureTableAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/exposure-table/`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const searchAPI = async (search) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/home-page/search`,
      {
        params: {
          search,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const scoreAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `http://marketsarket.in:3002/scoreurl/${eventId}`
    );
    return data?.score;
  } catch (error) {
    return error;
  }
};

export const getMatchScoreAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/auth/score-sport`,
      {},
      {
        params: {
          eventId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const upcomingFixtureAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/upcoming-fixture`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const dashboardBannerAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/dashboard-banner`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAnnouncementAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/auth/announcements`);
    return data;
  } catch (error) {
    return error;
  }
};
export const getBetsFromAccountLogs = async (id) => {
  console.log(id);
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/bets-from-log/${id}`,
      {
        // params: {
        //   accountLogId: id,
        // },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getProfitLossReportAPI = async (from, to, userName) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/profit-loss-report`,
      {
        from,
        to,
        userName,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const generateMFAAPI = async (password) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/generate-mfacode`,
      {
        password,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const resendCodeAPI = async (userName) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/auth/resend-otp`,
      {},
      {
        params: {
          userName,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const verifyCodeAPI = async (userName, otp, isDisableMfa = false) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/auth/verify-otp`,
      {},
      {
        params: {
          userName,
          otp,
          isDisableMfa,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getLiveStreamingUrlAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/streaming/${id}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getGamesKingLiveStreamingUrlAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/streaming/${id}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const disableMFAAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/user/disable-mfa`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};