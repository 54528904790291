import { AXIOS_INSTANCE } from "..";
import store from "../../redux/store";
import { BASE_URL, getToken } from "../../utils/constants";

export const getAllCricketMatchListAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/cricket/all-game-dashboard`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const placeCricketBetAPI = async (payload, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/cricket/bet/place`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const placeFancyCricketBetAPI = async (payload, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/cricket/bet/fancy-place`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getMyBetsCricketAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/cricket/my-bet/${eventId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getMatchDetailsCricketAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/cricket/match-details/${eventId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const deleteCricketBetAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/bet/bets/${id}/status?betStatus=DELETED`,
      {}
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCricketEventDetailsAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/cricket/event-data/${eventId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllMatchesOddsCricketAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/cricket/all-game-dashboard`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCricketOddsByMatchIdAPI = async (matchId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/cricket/odds/${matchId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getSportIframe = async (eventId, type) => {
  try {
    const data = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/get-tv-scorecard?eventId=${eventId}&type=${type}`
    );

    return data.data;
  } catch (error) {
    return error;
  }
};
