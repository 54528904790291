import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { currentBetsAPI, getInternationalBetsAPI } from "../../service/AuthService";
import toast from "react-hot-toast";

export default function InternationalReport() {
  const [fromDate, setFromDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(50);
  const [toDate, setToDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [data, setData] = React.useState([]);

  const getInternationalBets = async () => {
    const { response, message, code }  = await getInternationalBetsAPI({
      pageNumber: pageNumber,
      pageSize: pageSize,
      type: "ALL",
      startDate: new Date(fromDate),
      endDate: new Date(toDate),
      search: null,
      gameId: "",
    });
    if (code == 200) {
      setData(response);
    } else {
      toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    // let data_ = content.map((item) => ({
    //   ...item,
    //   rate: item.back_run ? item.back_run : item.lay_run,
    //   type: item.back_run ? "BACK" : "LAY",
    // }));
    // console.log("content", content);
    // setData(
    //   data_?.filter(
    //     (item) => item.event_type?.toLowerCase() === betType?.toLowerCase()
    //   )
    // );
    // setTotalElements(totalElements);
    // setTotalPages(totalPages);
    // setSize(size);
    // setTotalBets(totalBets);
    // setTotalAmount(totalAmount);
  };
  useEffect(() => {
    getInternationalBets();
  }, []);
  const handleSubmit = () => {
    getInternationalBets();
  };
  const isMobile = window.innerWidth < 786;
  return (
    <div
      style={{
        width: isMobile ? "100%" : "82%",
      }}
      className={` m-t-5`}
    >
      <div className="card">
        <div className="card-header">
          <h4 className="mb-0">International Casino Report</h4>
        </div>
        <div className="card-body container-fluid container-fluid-5">
          <div className="row row5">
            <div className={isMobile ? "col-12" : "col-2"}>
              <div className="form-group mb-0">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  placeholder="Select start date"
                />
              </div>
            </div>
            <div className={isMobile ? "col-12 mt-2" : "col-2"}>
              <div className="form-group mb-0">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  value={toDate}
                  placeholder="Select end date"
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
            <div
              onClick={handleSubmit}
              className={isMobile ? "col-12 mt-2" : "col-1"}
            >
              <button className="btn btn-primary btn-block">Submit</button>
            </div>
          </div>

          <div className="row row5 mt-2">
            <div className="col-12">
              <div className="table-responsive">
                <table
                  role="table"
                  aria-busy="false"
                  aria-colcount={3}
                  className="table b-table table-striped table-bordered"
                  id="__BVID__109"
                >
                  <thead role="rowgroup" className="">
                    <tr role="row" className="">
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={1}
                        className="text-center"
                      >
                        S No
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={1}
                        className="text-center"
                      >
                        Date
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={2}
                        className="text-center"
                      >
                        Transaction Type
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={3}
                        className="text-right"
                      >
                        Amount
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={3}
                        className="text-center"
                      >
                        Game Name
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    {data?.map((item, index) => (
                      <tr role="row" className="">
                     
                        <td role="cell" className="text-center">
                        {index + 1}
                        </td>
                        <td role="cell" className="text-center">
                        {moment(item.createDate)
                                .utc()
                                .local()
                                .format("DD/MM/YYYY ")}
                        </td>
                        <td role="cell" className="text-center">
                          {item?.transType}
                        </td>
                        
                        <td
                          role="cell"
                          className={`text-right ${
                            item?.amount < 0 ? "text-danger" : "text-success"
                          }`}
                        >
                          {item?.amount}
                        </td>
                        <td role="cell" className="text-center">
                          {item?. gameName}
                        </td>
                       
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-12" />
          </div>
        </div>
      </div>
    </div>
  );
}

