import { AXIOS_INSTANCE } from "..";
import { CASINO_SERVICE_URL_INTERNATIONAL } from "../../utils/constants";

export const getInternationalGamesAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL_INTERNATIONAL}/internationalCasino/getGames`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getLaunchGameAPI = async (gameId) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CASINO_SERVICE_URL_INTERNATIONAL}/internationalCasino/launch_game`,
      {
        gameId,
        device: "mobile",
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
