import React from 'react'
import PokeroneDay from "../../../../assets/Sample/Poker1-Day.png"
import TwentyTwentyPoker from "../../../../assets/Sample/Poker-20-20.png"

export default function Poker() {
  return (
    <div className="tab-content">
  <div className="tab-pane active" id="all-casino">
    <div className="casino-list">
      <div className="casino-list-item">
        <a href="/casino/poker">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${PokeroneDay}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
      <div className="casino-list-item">
        <a href="/casino/poker20">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${TwentyTwentyPoker}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
      {/* <div className="casino-list-item">
        <a href="/casino/poker6">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/poker6.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div> */}
    </div>
  </div>
</div>

  )
}
