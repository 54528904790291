import React from 'react'
import Race20 from "../../../../assets/Sample/Race-20.png"

export default function Race() {
  return (
<div className="tab-content">
  <div className="tab-pane active" id="all-casino">
    <div className="casino-list">
      {/* <div className="casino-list-item">
        <a href="/casino/race2">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/race2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div> */}
      <div className="casino-list-item">
        <a href="/casino/race20">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${Race20}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
      {/* <div className="casino-list-item">
        <a href="/casino/race17">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/race17.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div> */}
    </div>
  </div>
</div>
  )
}
