import React from 'react'
import TwentyTwentyDregonTiger from "../../../../assets/Sample/20-20-Drangon-Tiger.png"
import OneDayDregonTiger from "../../../../assets/Sample/Dragon-Tiger-1-Day.png"
import DTL from "../../../../assets/Sample/20-20-DTL.png"
import DrangonTigertwNew from "../../../../assets/Sample/20-20-Drangon-Tiger-Two.png"

export default function DragonTiger() {
  return (
<div className="tab-content">
  <div className="tab-pane active" id="all-casino">
    <div className="casino-list">
      <div className="casino-list-item">
        <a href="/casino/dt20">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${TwentyTwentyDregonTiger}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
      <div className="casino-list-item">
        <a href="/casino/dt6">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${OneDayDregonTiger}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
      {/* <div className="casino-list-item">
        <a href="/casino/dtl20">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${DTL}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div> */}
      <div className="casino-list-item">
        <a href="/casino/dt202">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${DrangonTigertwNew}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`
            }}
          />
        </a>
      </div>
    </div>
  </div>
</div>
  )
}
