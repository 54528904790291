import React from 'react'
import CasinoWAR from "../../../../assets/Sample/Casino-War.png"

export default function CasinoWar() {
  return (
<div className="tab-content">
  <div className="tab-pane active" id="all-casino">
    <div className="casino-list">
      <div className="casino-list-item">
        <a href="/casino/war">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${CasinoWAR}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
    </div>
  </div>
</div>
  )
}
