import React from "react";
import { useParams } from "react-router-dom";

export default function SportScoreCardIframe() {
  const { id } = useParams();
  return (
    <iframe
      style={{ width: "100%" }}
      src={`https://score.proexch.in/#/score1/${id}`}
    />
  );
}
