import React from 'react'
import AmarAkbarAnthony from "../../../../assets/Sample/Amar-Akbar-Anthony.png"
import BollywoodCasino from "../../../../assets/Sample/Bollywood-Casino.png"

export default function Bollywood() {
  return (
<div className="tab-content">
  <div className="tab-pane active" id="all-casino">
    <div className="casino-list">
      {/* <div className="casino-list-item">
        <a href="/casino/aaa2">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/aaa2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div> */}
      <div className="casino-list-item">
        <a href="/casino/aaa">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${AmarAkbarAnthony}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
      <div className="casino-list-item">
        <a href="/casino/btable">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${BollywoodCasino}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
    </div>
  </div>
</div>

  )
}
