import React, { useEffect } from "react";
import { getBannerAPI } from "../service/AuthService";

export default function InitialRule() {
  const [show, setShow] = React.useState(false);
  const [banners, setBanners] = React.useState([]);
  const getBanner = async () => {
    const { response } = await getBannerAPI();
    setBanners(response);
  };
  useEffect(() => {
    getBanner();
    if (localStorage.getItem("initialRule") === "true") {
      setShow(false);
    } else {
      localStorage.setItem("initialRule", "true");
      setShow(true);
    }
  }, []);
  return (
    <div
      id="welcomeMessageOpenModal"
      tabIndex={-1}
      aria-labelledby="welcomeMessageOpenModalLabel"
      className="modal fade homemessagemodal show"
      aria-modal="true"
      role="dialog"
      style={{ display: !show ? "none" : "block", color: "black" }}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header py-3">
            <h5 id="welcomeMessageOpenModalLabel" className="modal-title">
              <span />
            </h5>
            <button
              onClick={() => setShow(false)}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              className="btn-close"
            >
              {/*  <i className="fas fa-times" /> */}
            </button>
          </div>
          <div>
            {banners?.length > 0 && (
              <img className="img-fluid" src={banners[0]?.link} />
            )}
            {/*  <div
              style={{ boxShadow: "0px 0px 5px", padding: 10 }}
              className="ng-star-inserted"
            >
              <h2>Dear Client,</h2>
              <h3>
                {" "}
                Welcome to{" "}
                <a href="javascript:void(0)">
                  <span className="text-uppercase"> 'bvm99.com'</span>
                </a>
              </h3>
              <p className="mb-1 welcome-text">
                <span className="disclaimer">Disclaimer:</span> This website
                is intended for entertainment purpose only. Additionally, this
                webiste is not use for commerical purpose. We are not liable
                for any damages or losses incurred from the use of this
                website.{" "}
              </p>
            </div>
            <div
              className="mt-3 font-hindi ng-star-inserted"
              style={{ boxShadow: "0px 0px 5px", padding: 10 }}
            >
              <h2>प्रिय ग्राहक,</h2>
              <h3>
                {" "}
                आपका स्वागत है{" "}
                <a href="javascript:void(0)">
                  <span className="text-uppercase"> 'bvm99.com'</span>
                </a>
              </h3>
              <p className="mb-1 welcome-text">
                <span className="disclaimer">अस्वीकरण:</span>
                यह वेबसाइट केवल मनोरंजन के उद्देश्य से बनाई गई है। इसके
                अतिरिक्त, इस वेबसाइट का उपयोग व्यावसायिक उद्देश्य के लिए नहीं
                किया जाता है। हम इस वेबसाइट के उपयोग से होने वाली किसी भी
                क्षति या हानि के लिए उत्तरदायी नहीं हैं।{" "}
              </p>
            </div>

            <div className="text-right mt-3">
              <button
                onClick={() => setShow(false)}
                className="btn btn-primary"
                style={{ minWidth: 100 }}
              >
                {" "}
                OK{" "}
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
