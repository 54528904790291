import React from "react";

export default function Cmeter({ data }) {
  const { casinoIndividualResultDTO } = data || {};
  const {
    casinoIndividualResultMaps = [],
    casinoCardResultMaps = [],
    marketId,
    createdAt,
  } = casinoIndividualResultDTO || {};

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime + "Z");
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  // Separate the high and low cards from the casinoCardResultMaps
  const highCards = casinoCardResultMaps.find(
    (map) => map.resultFor === "High Cards"
  );
  const lowCards = casinoCardResultMaps.find((map) => map.resultFor === "Low Cards");

  return (
    <div className="modal-body">
      <div className="casino-result-modal">
        <div className="casino-result-round-id">
          <span>
            <b>Market Id: </b> {marketId || "N/A"}
          </span>
          <span>
            <b>Match Time: </b>{" "}
            {createdAt ? formatDateTime(createdAt) : "N/A"}
          </span>
        </div>

        <div className="row mt-2 align-items-center">
          <div className="col-10 text-center">
            {/* Low Cards */}
            <div className="row align-items-center">
              <div className="col-2">Low Cards</div>
              <div className="col-10">
                <div className="casino-result-cards">
                  {lowCards?.result?.map((card, index) => (
                    <img
                      key={index}
                      src={`https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${card}.png`}
                      alt={`Low Card ${card}`}
                    />
                  ))}
                </div>
              </div>
            </div>

            {/* High Cards */}
            <div className="row align-items-center">
              <div className="col-2">High Cards</div>
              <div className="col-10">
                <div className="casino-result-cards">
                  {highCards?.result?.map((card, index) => (
                    <img
                      key={index}
                      src={`https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${card}.png`}
                      alt={`High Card ${card}`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 text-center">
            <div className="casino-result-cards" />
          </div>
        </div>

        <div className="row mt-2 justify-content-center">
          <div className="col-md-6">
            <div className="casino-result-desc">
              {/* Display winner information */}
              {casinoIndividualResultMaps.map((result) => (
                <div className="casino-result-desc-item" key={result.id}>
                  <div>{result.resultFor}</div>
                  <div>{result.result.join(" | ")}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
